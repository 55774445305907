import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { PHASE_MEASURING, reuseMeasurements } from '../../actions/recommendationAction';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VerticalSlider from './VerticalSlider';
import ChartSlider from './ChartSlider';

import { COLORS } from '../../common/Colors';
import Measuring from '../recommendation/phases/Measuring';
import { convertKeyToValueOfSizeChart } from '../../common/helper';

const { BLUE } = COLORS;

const Container = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    @media(max-width: 600px) {
        width: 100%;
    }
`
const NotFound: any = styled.h2`
    font-size: 18px;
`;

const ProductName: any = styled.h2`
    font-size: 18px;
    margin-top: 35px;
`;

const SubContainer: any = styled.div`
    min-height: 400px;
    display: flex;
    width: 80vw;
    justify-content: center;
    @media (max-width: 600px) {
        border-top: 2px solid rgba(0, 55, 119, 0.5);
        border-bottom: 2px solid rgba(0, 55, 119, 0.5);
    }
`;

const SizeContainer = styled.div`
    min-height: 400px;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    ::-webkit-scrollbar {
        display: none;
    }
    @media (max-width: 600px) {
        width: calc(100vw - 65px);
    }
`;

const CircumferenceDiv = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 150px;
    color: ${BLUE};
    border-right: 2px solid rgba(0,55,119,0.5);
    @media(max-width: 600px) {
        width: 100%;
    }
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 2px solid rgba(0,55,119,0.5);
    color: ${BLUE};
`;

const Text: any = styled.p`
    font-weight: bold;
    margin: 10px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
        font-size: 14px;
        height: 20px
    }
`;

const GridContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
`;

const GridItem: any = styled.div`
    padding: 5px;
    min-width: 50px;
    background-color: ${(props: any) => ((props.index) % 2 === 0) ? '' : 'rgba(0, 55, 119, 0.05)'};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    width: 100%;
    min-height: 300px;
    display: grid;
`;

// const HeadingSection: any = styled.div`
//     width: 50%;
//     .heading {
//         padding: 15px;
//         font-size: 20px;
//         font-weight: 700;
//     }
//     @media(max-width: 600px) {
//         font-size: 18px;
//         width: 100%;
//     }
// `;

const ButtonContainer = styled.div`
    display: flex;
    width: 300px;
    height: 40px;
    justify-content: space-around;
    border: 2px solid rgba(0, 55, 119, 0.5);
    border-radius: 40px;
    color: ${BLUE};
    margin-top: 25px;
    margin-bottom: 25px;
    @media (max-width: 600px) {
        height: 24px;
        font-size: 12px;
    }
`;

const ButtonText: any = styled.div`
    height: 100%;
    width: 50%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    text-transform: uppercase;
    background-color: ${(props: any) => props.select ? 'rgba(0, 55, 119, 0.2)' : ''};
`;

const Measurements = [
    { value: 'circumference.head', label: 'Head circ.' },
    { value: 'circumference.neck', label: 'Neck circ.' },
    { value: 'circumference.chest', label: 'Chest circ.' },
    { value: 'circumference.underBust', label: 'Under bust circ.' },
    { value: 'circumference.waist', label: 'Waist circ.' },
    { value: 'circumference.trouserWaist', label: 'Trouser waist circ.' },
    { value: 'circumference.hip', label: 'Hip circ.' },
    { value: 'circumference.thigh', label: 'Thigh circ.' },
    { value: 'circumference.knee', label: 'Knee circ.' },
    { value: 'circumference.calf', label: 'Calf circ.' },
    { value: 'circumference.ankle', label: 'Ankle circ.' },
    { value: 'circumference.biceps', label: 'Biceps circ.' },
    { value: 'circumference.elbow', label: 'Elbow circ.' },
    { value: 'circumference.forearm', label: 'Forearm circ.' },
    { value: 'circumference.wrist', label: 'Wrist circ.' },
    { value: 'width.shoulder', label: 'Shoulder width' },
    { value: 'length.head', label: 'Head length' },
    { value: 'length.neck', label: 'Neck length' },
    { value: 'length.upperBody', label: 'Upper body length' },
    { value: 'length.hip', label: 'Hip length' },
    { value: 'length.upperLeg', label: 'Upper leg length' },
    { value: 'length.lowerLeg', label: 'Lower leg length' },
    { value: 'length.upperArm', label: 'Upper arm length' },
    { value: 'length.lowerArm', label: 'Lower arm length' },
    { value: 'length.arm', label: 'Arm length' },
    { value: 'length.hand', label: 'Hand length' },
    { value: 'length.foot', label: 'Foot length' },
    { value: 'length.insideLeg', label: 'Inseam length' },
    { value: 'weight.body', label: 'Weight'},
    { value: 'height.body', label: 'Height'}
];

const SizeChartInformation = (props: any) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    // const location = useLocation()
    const url = new URL(window.location.href);
    const navigate = useNavigate();
    // const search = (location.search).replace('?', '');
    const { phase } = useSelector(
        (state: any) => state.recommendationReducer
    );
    const measurementData = useSelector(
        (state: any) => state.recommendationReducer.measurements
    );
    useEffect(() => {
        if (Object.keys(sliderValue).length > 0) {
            const keys = Object.keys(sliderValue);
            const values: any = Object.values(sliderValue);
            const obj: any = {};
            keys.forEach((key, index) => {
                const [key1, key2] = key.split('.');
                if (!obj[key1]) obj[key1] = {};
                obj[key1][key2] = values[index] * 10;
            })
            setMeasurement(obj);
        }
        // eslint-disable-next-line
    }, []);

    const sizeChartData = useSelector((state: any) => state.recommendationReducer.sizeChart);
    const [measurements, setMeasurement] = useState<any>({});
    const [chartTableSelect, setChartTableSelect] = useState('measure');
    const convertedValue = convertKeyToValueOfSizeChart(measurementData);
    const [sliderValue, setSliderValue] = useState({});

    useEffect(() => {
        setSliderValue(convertedValue);
        setMeasurement({ ...measurementData })
        // eslint-disable-next-line
    }, [measurementData])

    const measurementLabel = (val: string) => {
        const measurement: any = Measurements.find(v => v.value === val);
        return measurement.label;
    }

    const handleOnChange = (value: any, measurement: string) => {
        let newObj: any = { ...sliderValue };
        newObj[measurement] = value;
        setSliderValue({ ...newObj })
        const [key1, key2] = measurement.split('.');

        const obj: any = { ...measurements };
        if (!obj[key1]) obj[key1] = {};
        obj[key1][key2] = key1 === 'weight' ? value : value * 10;
        setMeasurement(obj);
    }

    const handleOnClick = () => {
        navigate(`/${url.search}`);
        dispatch(reuseMeasurements({ ...measurements }))
    }

    const hasAllKeys = (measurement: any, secondObject: any) => {
        for (let i = 0; i < measurement.length; i++) {
            const firstObject = measurement[i];
            if (!secondObject[firstObject.measurement]) {
                return false;
            }

        }
        return true;
    }

    const openRecommendation = () => {
        const currentUrl = window.location.href;
        const path = '/recommendation';
        const urlParams = new URLSearchParams(currentUrl.split('?')[1]);
        const measurementIdValue = urlParams.get('measurementId');
        if (!measurementIdValue || measurementIdValue === 'null' || measurementIdValue === 'undefined') {
            urlParams.delete('measurementId');
        }
        const newUrl = `${process.env.REACT_APP_MY_SIZEY}/measurement?${urlParams.toString()}&path=${path}`;
        window.location.href = newUrl;
    }

    return (
        <Container>
            {sizeChartData && sizeChartData.isNotSizeChart && <NotFound >{sizeChartData.message}</NotFound>}
            {phase === PHASE_MEASURING && <Measuring />}
            {!(phase === PHASE_MEASURING) && sizeChartData && !sizeChartData.isNotSizeChart && <>
                {/* <HeadingSection >
                    <Typography component='h3' variant='h5' className='heading' >{t('helpUsToSaveThePlanet')}</Typography>
                    <Typography component='h3' variant='h5' className='heading' >{t('setYourOwnBodyMeasurement')}</Typography>
                </HeadingSection>
                <ProductName className='heading'>{sizeChartData.name}</ProductName> */}
                <ButtonContainer>
                    <ButtonText style={{ borderBottomLeftRadius: '15px', borderTopLeftRadius: '15px' }} onClick={() => setChartTableSelect('measure')} select={chartTableSelect === 'measure'}>{t('measure')}</ButtonText>
                    <ButtonText style={{ borderBottomRightRadius: '15px', borderTopRightRadius: '15px' }} onClick={() => setChartTableSelect('chart')} select={chartTableSelect === 'chart'}>{t('chart')}</ButtonText>
                </ButtonContainer>
                <SubContainer>
                    {
                        sizeChartData?.sizes?.map((val: any, index: number) => {
                            return <LabelContainer key={index}>
                                <Text >{t(val.label)}</Text>
                                <GridContainer>
                                    <Text style={{ margin: '0px', marginBottom: '30px', height: '16px' }}>{t('size')}</Text>
                                    <Box >
                                        {
                                            [...(val?.sizes || [])].reverse().map((v: any, index: number) => {
                                                return <GridItem key={v} index={index + 1}>{v}</GridItem>
                                            })
                                        }
                                    </Box>
                                </GridContainer>
                            </LabelContainer>

                        })}
                    <SizeContainer>
                        {
                            sizeChartData?.measurements?.map((val: any, index: number) => {
                                const MIN = val?.values[0].min;
                                const MAX = val?.values[val?.values.length - 1].max;
                                return <CircumferenceDiv key={index}>
                                    <Text>{t(measurementLabel(val.measurement))}</Text>
                                    <GridContainer>
                                        {chartTableSelect === 'measure' && val.values.length > 0 && <VerticalSlider values={val.values} value={sliderValue} MIN={MIN} MAX={MAX} measurement={val.measurement} unit={val.unit} handleOnChange={handleOnChange} />}
                                        {chartTableSelect === 'chart' && val.values.length > 0 && <ChartSlider values={val.values} value={sliderValue} MIN={MIN} MAX={MAX} measurement={val.measurement} unit={val.unit} handleOnChange={handleOnChange} />}
                                    </GridContainer>
                                </CircumferenceDiv>
                            })
                        }
                    </SizeContainer>
                </SubContainer>
                {sizeChartData?.measurements.length > 0 && hasAllKeys(sizeChartData?.measurements, sliderValue) ? <Button style={{ marginTop: '35px' }} variant='contained' color='primary' onClick={handleOnClick}>
                    {t('showMySize')}
                </Button> :
                <>
                    <ProductName className='heading'>{t('give_your_measures')}</ProductName>
                </>
                    }
                    <Button type='submit' variant='contained' color='primary' onClick={openRecommendation}>
                        {t('make_new_measuring')}
                    </Button>
            </>}
        </Container>
    )
}

export default SizeChartInformation;