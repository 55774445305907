import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useContext, useEffect } from 'react'

import { getShopsUrl, getUserInfo, getUserMeasurements, userMeasurement } from '../actions/UserAction'
import { FirebaseContext } from '../providers/FirebaseProviders'
import { getSizeChart } from '../actions/recommendationAction'

const AuthRedirect = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate    ();
    const authContext = useContext(FirebaseContext);
    const location = useLocation()
    useEffect(() => {
        (async () => {
            const queryParams = new URLSearchParams(location.search);
            if (authContext.user) {
                const token = await authContext?.user?.getIdToken(true)
                await dispatch(getShopsUrl(token))
                await dispatch(getUserInfo(token))
                if (location.search.includes('saved')) {
                    const measurement_id = queryParams.get('measurementId');
                    if (measurement_id) {
                        await dispatch(userMeasurement({ measurement_id, tape: true }, token));
                    }
                    navigate({
                        search: queryParams.toString().replace('&saved=', ''),
                    })
                }
                await dispatch(getUserMeasurements(token, navigate));
            }
            const UPC = queryParams.get('UPC') || queryParams.get('upc');
            const productId = queryParams.get('productId');
            const brand = queryParams.get('brand');
            const garment = queryParams.get('garment')
            if (UPC || productId || (brand && garment)) {
                await dispatch(getSizeChart());
            }
        })()
        // eslint-disable-next-line 
    }, [authContext, navigate])
}

export default AuthRedirect
