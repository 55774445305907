import { FunctionComponent, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import LogoImages from '../assets/images/sizey_logo_white.png'
import Moon from '../assets/images/moon.svg'
import Sun from '../assets/images/sun.svg'
import styled from 'styled-components'
import { FirebaseContext } from '../providers/FirebaseProviders';
import { useTranslation } from 'react-i18next';
import {  PHASE_FRONT_VIEW_INSTRUCTIONS, setClearBothPictures, setPicture, setRecommendations, setShowPhase, setSidePicture, SET_FRONT_PICTURE } from '../actions/recommendationAction';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../common/Colors';
import ConfirmationPopup from '../common/ConfirmationPopup'
import LoginButton from '../common/LoginButton';

const { WHITE, BLUE, BLACK } = COLORS;

interface DropdownContentProps {
  open: boolean;
}

const Image: any = styled.img`
  height: ${(props: any) => props.height ? `${props.height}px` : '25px'};
  cursor: pointer;
  z-index: 99;
  width: ${(props: any) => props.width ? `${props.width}px` : '95px'};
`

const NavBarIcons = styled.div`
  display: flex;
  padding: 26px 30px;
  justify-content: space-between;
  .MuiButton-root {
    padding: 0px;
  }
  background-color: ${BLUE};
  @media(max-width: 768px) {
    padding: 20px 30px;
  }
  @media(max-width: 600px) {
    padding: 20px 15px;
  }
  .log-out{
    color: ${WHITE};
    cursor: pointer;
    z-index: 99;
    padding-right: 10px;
    font-weight: 500;
    font-size: 20px;
    @media(max-width: 600px){
      display: none;
    }
  }
  .menu-btn {
      color: ${WHITE};
      z-index: 999;
  }
`

const ChangeLanguage = styled.div`
  display: flex;
`
const ThemeChange = styled.div`
  background: ${WHITE};
  border-radius: 20px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px;
`;

const LanguageDropdown = styled.div`
  position: relative;
`;

const DropdownButton = styled.div`
  height: 25px;
  width: 40px;
  margin-left: 20px;
  background: ${WHITE};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${BLACK};
`;

const DropdownContent = styled.div<DropdownContentProps>`
  position: absolute;
  top: 30px; /* Adjust this value based on your design */
  background-color: ${WHITE};
  border-radius: 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  display: ${({ open }) => (open ? 'block' : 'none')}; /* Show or hide based on 'open' state */
`;

const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const DeskTopView = styled.div`
  display: flex;
`;

const Navbar: FunctionComponent<any> = ({ theme, setMode }: any) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate();  
  const dispatch = useDispatch();
  const url = new URL(window.location.href);
  const { user } = useContext(FirebaseContext);

  // const [language, setLanguage] = useState('en');
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handlePopupClose = () => {
    setOpen(false);
  };

  const handleLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  const goToHome = () => {
    dispatch(setClearBothPictures());
    dispatch(setRecommendations(undefined))
    navigate(`/${url.search}`);
  };

  const handleDarkTheme = () => {
    if (theme.name === 'Light') {
      setMode('dark')
    } else {
      setMode('light')
    }
  }

  const handleOnClick = () => {
    navigate(`/${url.search}`)
    dispatch(setShowPhase(PHASE_FRONT_VIEW_INSTRUCTIONS))
    dispatch(setSidePicture(null))
    dispatch(setPicture(
      SET_FRONT_PICTURE,
      null
    ))
  }

  return (
    <div>
      <ConfirmationPopup handleClose={handlePopupClose} handleOnClick={handleOnClick} open={open} />
      <NavBarIcons>
        <Image onClick={goToHome} src={LogoImages} alt='logo-image' />
        <ChangeLanguage>
        {!user && <LoginButton t={t} />}
          <DeskTopView>
            <LanguageDropdown>
              <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                {i18n.language.includes('en') ? 'EN' : i18n.language.includes('fi') ? 'FI' : 'SW'}
              </DropdownButton>
              <DropdownContent open={isOpen}>
                <DropdownItem onClick={() => handleLanguageChange('en')}>English</DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange('fi')}>Finnish</DropdownItem>
                <DropdownItem onClick={() => handleLanguageChange('sw')}>Swedish</DropdownItem>
              </DropdownContent>
            </LanguageDropdown>
            <ThemeChange>
              <Image width={18} height={18} src={theme.name === 'Light' ? Moon : Sun} alt='theme' onClick={handleDarkTheme} />
            </ThemeChange>
          </DeskTopView>
        </ChangeLanguage>
      </NavBarIcons>
    </div>

  )
}

export default Navbar;