import { FunctionComponent } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import styled from "styled-components";
import { ThemeProvider } from 'styled-components';
import Token from './services/Token';
import Recommendation from './views/recommendation/phases/Recommendation';
import AuthRedirect from './services/AuthRedirect';
import Navbar from './views/Navbar'
import { GlobalStyles } from './globalStyle'
import { useTheme } from './theme/useTheme';
import { useTranslation } from 'react-i18next';
import { PHASE_FRONT_VIEW_CAMERA, PHASE_SIDE_VIEW_CAMERA } from './actions/recommendationAction';
import { useSelector } from 'react-redux';

const AppRoutes: FunctionComponent = () => {
  const { theme, setMode } = useTheme();

  const Footer: any = styled.footer`
  width: 100%;
  font-size: 16px;
  color: ${(props: any) => props.theme.colors.color};
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding 20px 0px;
`;

  AuthRedirect()
  const { t } = useTranslation();
  const url = new URL(window.location.href);
  const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
  const productId: any = url.searchParams.get('productId');
  const { phase  } = useSelector(
    (state: any) => state.recommendationReducer
  );

  const isCamera = phase === PHASE_FRONT_VIEW_CAMERA || phase === PHASE_SIDE_VIEW_CAMERA;

  return (
    <div style={{
      height: '100%',
      minHeight: '90vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto'
    }}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Navbar theme={theme} setMode={setMode} />
        <div>
          <Routes>
            <Route path="/" element={<Recommendation />} />
            <Route path="/auth/token" element={<Token />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        {(!isCamera && (!UPC || !productId) ) && <div><Footer theme={theme}>
          © Sizey 2024 |{" "}
          <a href="https://sizey.ai" target="blank">
            {" "}
            {t("About us")}{" "}
          </a>
        </Footer></div>}
      </ThemeProvider>
    </div>

  )
}

export default AppRoutes;