export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);


export const convertKeyToValue = (obj: any) => {
    const measurement: any = {};
    if(obj) {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object') {
                Object.keys(obj[key]).forEach(unit => {
                    measurement[unit + '.' + key] = obj[key][unit];
                })
            }
        })
    }
    return measurement;
}

export const convertKeyToValueOfSizeChart = (obj: any) => {
    const measurement: any = {};
    if(obj) {
        Object.keys(obj).forEach(key => {
            if (typeof obj[key] === 'object') {
                Object.keys(obj[key]).forEach(unit => {
                    if (key !== 'weight') {
                        measurement[key + '.' + unit] = obj[key][unit] / 10;
                    } else if (key === 'weight') {
                        measurement[key + '.' + unit] = obj[key][unit];
                    }
                })
            }
        })
    }
    return measurement;
}
