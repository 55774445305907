import { useContext, Fragment, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { setMeasurements, setRecommendations, } from "../../../actions/recommendationAction";
import {
  startMenu,
  setShowPhase,
  reuseMeasurementId,
  PHASE_MISSING_API_KEY,
} from "../../../actions/recommendationAction";
import Landing from "../../Landing";
import { FirebaseContext } from "../../../providers/FirebaseProviders";
import SizeChartResult from "../../SizeChartResult";
import { COLORS } from "../../../common/Colors";
import { getMeasurement } from "../../../api/recommendation-api";
import MissingApiKey from "./MissingApiKey";

const { BLUE } = COLORS;

const Container: any = styled.div`
  width: "100%";
  height: 100%;
  margin: 0 auto;
`;

const Heading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 17px 0px 0px 0px;
`;

const TryButton = styled.div`
  margin-top: 1em;
  margin: 10px 30px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text};
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 80vh;
  .MuiButton-outlinedPrimary {
    color: ${BLUE};
    border: 2px solid ${BLUE};
    font-size: 18px;
    font-weight: bold;
  }
  .result-footer-container {
      margin-top: 150px;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const LoadingScreen = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.text};
  height: 100vh;
  
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    border: 4px solid ${({ theme }) => theme.colors.primary}; /* Choose your desired color */
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: ${spin} 1s linear infinite; /* Apply the spin animation */
  }
`;

const InnerBody = styled.div`
  font-size: 35px;
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

function Recommendation(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const measurementId = useSelector((state: any) => state.userInfo);
  const { sizeChart, recommendations, phase } = useSelector(
    (state: any) => state.recommendationReducer
  );
  const location: any = useLocation();
  const dispatch = useDispatch();

  const authContext = useContext(FirebaseContext);
  const query = new URLSearchParams(location.search);
  const paramsMeasurementId = query.get("measurementId");
  const url = new URL(window.location.href);
  const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
  const productId: any = url.searchParams.get('productId');
  const brand: any = url.searchParams.get('brand');
  const garment: any = url.searchParams.get('garment')
  const apiKey = query.get("apikey");

  const [urlMeasurementId, setUrlMeasurementId] = useState('');

  const handleRoutesByParams = async () => {
    let id = null;

    if (paramsMeasurementId !== "null" && paramsMeasurementId !== 'undefined') {
      id = paramsMeasurementId;
    }
    else if (measurementId?.measurements.length > 0) {
      const tapeMeasurement = measurementId.measurements.filter((m: any) => m.tape);
      const digitalMeasurement = measurementId.measurements.filter((m: any) => !m.tape);
      if (measurementId.user.useTapeMeasurement) {
        if (tapeMeasurement.length > 0) {
          id = tapeMeasurement[0].measurement_id;
        } else {
          id = digitalMeasurement[0].measurement_id;
        }
      } else {
        if (digitalMeasurement.length > 0) {
          id = digitalMeasurement[0].measurement_id;
        } else {
          id = tapeMeasurement[0].measurement_id;
        }
      }
    } else {
      id = null;
    }
    setUrlMeasurementId(id)
    const firstMeasurementId = id;

    if (!apiKey) {
      dispatch(setShowPhase(PHASE_MISSING_API_KEY));
    } else {
      if (paramsMeasurementId === "null" || paramsMeasurementId === 'undefined') {
        const newSearch: any = new URLSearchParams(location.search);
        let query1 = "?";
        for (const param of newSearch) {
          if (param[0] !== "measurementId") {
            query1 = query1 + "&" + param[0] + "=" + param[1];
          }
        }
        navigate(
          `${query1 + "&measurementId=" + firstMeasurementId}`
        );
        if (!UPC && !productId && !brand && !garment) {
          dispatch(reuseMeasurementId(firstMeasurementId));
        }
      } else if (paramsMeasurementId) {
        if (!UPC && !productId && !brand && !garment) {
          dispatch(reuseMeasurementId(paramsMeasurementId));
        }
      } else {
        dispatch(startMenu());
      }
    }
  };

  useEffect(() => {
    handleRoutesByParams();
    // eslint-disable-next-line
  }, [measurementId.measurements?.length]);

  useEffect(() => {
    if (urlMeasurementId) {
      getMeasurement(urlMeasurementId)
        .then((me) => dispatch(setMeasurements(me.measurement)))
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, [urlMeasurementId])

  const tryAgain = () => {
    dispatch(setRecommendations(undefined));
    if (!authContext.user && (!!UPC || !!productId)) {
      navigate(`/${url.search}`);
      }
  }
  return (
    <Container>
      <Fragment>
        <InnerBody>
          {phase === PHASE_MISSING_API_KEY && <MissingApiKey />}
        </InnerBody>
      </Fragment>
      
      {!sizeChart && ((!!UPC || !!productId) || (!!brand && !!garment)) ? <LoadingScreen /> : (((recommendations || typeof recommendations === 'boolean') && ((UPC || productId) || (brand && garment))) ?
          <ResultContainer> {(!recommendations || recommendations.length === 0) ?
            <div>
              <Heading>{t('no_recommendation')}</Heading>
              <TryButton
                onClick={tryAgain}
              >
                {t("Try Again")}
              </TryButton>
            </div>
            :
            <SizeChartResult />}
          </ResultContainer>
          :
          <Fragment>
            {(!UPC && !productId && !brand && !garment) ? (paramsMeasurementId!=='null') ? null: apiKey ? <Landing /> : null
              :
              !recommendations && <Landing />}
          </Fragment>)}
    </Container>
  );
}

export default Recommendation;
