import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';
import { COLORS } from '../../common/Colors';

const { BLUE } = COLORS;

const Container = styled.div`
    .MuiSlider-root {
        position: absolute;
        left: 0px;
    }
    .MuiSlider-thumb {
        color: transparent;
        width: 20px;
        height: 20px;
        margin-left: -28px;
        margin-bottom: -2px;
        border-left: 10px solid transparent;
        border-right: 19px solid ${BLUE};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-radius: 0%;
    }

    .MuiSlider-track {
        color: ${BLUE};
        width: 1px;
    }
    
    .MuiSlider-rail {
        color: ${BLUE};
        width: 1px;
        opacity: 5;
    }
    .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
        box-shadow: 0 0;
    }
    .MuiSlider-root.MuiSlider-vertical {
        padding: 0px;
    }
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const Box = styled.div`
    width: 100%;
    display: grid;
`;

const Text: any = styled.p`
    font-weight: bold;
    margin: 0px;
    margin-bottom: 30px;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;

const GridItem: any = styled.div`
    padding: 5px;
    background-color: ${(props: any) => ((props.index) % 2 === 0) ? '' : 'rgba(0, 55, 119, 0.05)'};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SubContainer = styled.div`
    display: flex;
    height: 100%;
`;

const useStyles = makeStyles({
    root: {
        position: 'relative',
    },

});

export default function ChartSlider(props: any) {
    const classes = useStyles();
    const { values, value, measurement, handleOnChange, MIN, MAX, unit } = props;

    const changValue = (e: any, newValue: number | number[]) => {
        handleOnChange(newValue, measurement)
    }
    return (
        <Container>
            <Text>{value[`${measurement}`] ? value[`${measurement}`].toFixed(2) : 0}{unit || "cm"}</Text>
            <SubContainer>
                <Box >
                    {
                        [...(values || [])].reverse().map((v: any, index: number) => {
                            return <GridItem key={index} index={index + 1}>{v.min}-{v.max}</GridItem>
                        })
                    }
                </Box>
                <div className={classes.root}>
                    <Slider
                        min={MIN}
                        max={MAX}
                        onChange={changValue}
                        step={0.5}
                        value={value[measurement] ? value[measurement] : 0}
                        orientation="vertical"
                        aria-labelledby="vertical-slider"
                    />
                </div>
            </SubContainer>
        </Container>
    );
}
