import { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SizeChartInformation from '../views/sizeChart/SizeChartInformation';
import { useSelector } from 'react-redux'
import { COLORS } from '../common/Colors'

const { BLUE } = COLORS;

const Container: any = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    .MuiButton-containedPrimary {
        width: 430px;
        height: 45px;
        margin-top: 30px;
        font-weight: bold;
        border-radius: 10px;
        font-weight: 700;
        @media(max-width: 600px) {
            font-size: 13px;
            width: 80%;
        }
    }
    
    .MuiGrid-justify-content-xs-center {
        margin-top: 30px;
        margin-bottom: 20px;
    }
   
    @media(max-width: 600px) {
        width: 100%;
    }
    .MuiButton-outlinedPrimary {
        color: ${BLUE};
        border: 2px solid ${BLUE};
        font-size: 18px;
        font-weight: bold;
    }
    .result-footer-container {
        margin-top: 150px;
    }
`




const Landing = (props: any) => {
    const { i18n } = useTranslation()
    const navigate = useNavigate();
    const url = new URL(window.location.href);
    const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
    const productId: any = url.searchParams.get('productId');
    const brand: any = url.searchParams.get('brand');
    const garment: any = url.searchParams.get('garment');

    useEffect(() => {
        i18n.changeLanguage(i18n.language)
        // eslint-disable-next-line
    }, [i18n.language])

    const { recommendations } = useSelector((state: any) => state.recommendationReducer);

    return (
        <Container >
            {!recommendations && (UPC || productId || (brand && garment)) && <SizeChartInformation navigate={navigate}/>}
        </Container>
    )
}

export default Landing
