import { rootReducer } from './reducers/rootReducer'
import { createStore, compose, applyMiddleware, Middleware, MiddlewareAPI, Dispatch } from 'redux'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const thunkMiddleware: Middleware = (api: MiddlewareAPI) => (next: Dispatch) => (action: any) => {
  if (typeof action === 'function') {
    return action(api.dispatch, api.getState);
  }
  return next(action);
};
export const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunkMiddleware), composeEnhancers())
);
