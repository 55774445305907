
import React, { useContext, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import { tapeMeasurementApi, updateTapeMeasureApi } from '../api/recommendation-api'
import { setRecommendations } from '../actions/recommendationAction'
import { COLORS } from '../common/Colors'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { FirebaseContext } from '../providers/FirebaseProviders'
import { getSizeChart } from '../actions/recommendationAction'
import DesignGIF from "../assets/images/design.gif";

const { BLUE } = COLORS;

const SaveButton = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 20px;
    cursor: pointer;
`;

const Heading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    text-transform: uppercase;
    font-size: 24px;
    margin: 17px 0px 0px 0px;
`;

const SubHeading = styled.h2`
    color: ${({ theme }) => theme.colors.text};
    font-size: 12px;
    text-transform: uppercase;
`;

const SaveContainer = styled.div`
    bottom: 0px;
    width: 100%;
    @media (max-width: 600px) {
        border-top: 2px solid ${BLUE};
    }
`;

const ProductInfo = styled.div`
    font-size: 18px;
    translate: 0 35px;
    margin: 0 30px;
    color: #0481b3;
`;

const VariationList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
`;

const VariationItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    flex: 1 1 calc(50% - 10px);
    min-width: 250px;
    justify-content: center;
    gap: 1rem;
`;

const VariationDetails = styled.div`
    font-size: 16px;
    color: #0481b3;
`;

const Highlight = styled.span`
    font-weight: bold;
    color: #0481b3;
`;

const SelectButton = styled.button`
    background-color: #0481b3;
    color: white;
    padding: 10px 20px;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #035f82;
    }
`;

const DesignGifImage = styled.img`
  text-align: center;
  width: 64px;
  margin: 0 auto;
`;

const MeasuringText = styled.div`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  color: ${({ theme}) => theme.colors.text};
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;

const SizeChartResult = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const location = useLocation();
    const authContext = useContext(FirebaseContext);
    const navigate = useNavigate();
    const search = (location.search).replace('?', '');
    const url = new URL(window.location.href);
    const apiKey: any = url.searchParams.get("apikey");
    const paramsMeasurementId = url.searchParams.get("measurementId");
    const savingId = url.searchParams.has("saved");
    const UPC: any = url.searchParams.get('UPC') || url.searchParams.get('upc');
    const productId: any = url.searchParams.get('productId');
    const brand: any = url.searchParams.get('brand');
    const garment: any = url.searchParams.get('garment');
    const [showMessage, setMessage] = useState(false);
    const [isSizeChartLoaded, setIsSizeChartLoaded] = useState(false);

    const { recommendations, measurements, measurementFailed, sizeChart } = useSelector((state: any) => state.recommendationReducer);

    const recommendation = recommendations && recommendations.length > 0 ? recommendations[0] : null;

    const eventMessages = useMemo(() => ({
        measurements: { event: "sizey-measurements", measurements },
        recommendation: { event: "sizey-recommendation", recommendation },
        recommendations: { event: "sizey-recommendations", recommendations }
    }), [measurements, recommendation, recommendations]);

    const postMessage = async () => {
        if (!measurementFailed) {
            await eventPostMessage(targetWindow);
        }
    }

    const targetWindow = (message: any, hosts: string) => {
        if (window.opener) {
            window.opener.postMessage(message, hosts);
        }
        window.postMessage(message, hosts);
    };

    useEffect(() => {
        if(recommendations && measurements){
            postMessage();
        }
        (async () => {
            if (UPC || productId || (brand && garment)) {
                await dispatch(getSizeChart(UPC, productId, sizes));
                setIsSizeChartLoaded(true);
            }
        })()
        // eslint-disable-next-line
    }, [recommendations, measurements]);

    const eventPostMessage = async (windowPostMessage: any) => {
        await fetch(`${process.env.REACT_APP_LOGIN_API_BASE_URL}apikey`, {
            headers: new Headers({
                "Content-Type": "application/json",
                "X-Sizey-Key": apiKey,
            }),
        })
            .then((doc) => doc.json())
            .then((doc) => {
                windowPostMessage(
                    { event: "sizey-measurements", measurements },
                    doc.hosts
                );
                windowPostMessage(
                    { event: "sizey-recommendation", recommendation: recommendation },
                    doc.hosts
                );
                windowPostMessage(
                    {
                        event: "sizey-recommendations",
                        recommendations: recommendations,
                    },
                    doc.hosts
                );
            });
    };


    const handleOnClick = async () => {
        if (paramsMeasurementId && paramsMeasurementId !== 'null' && paramsMeasurementId !== 'undefined' && savingId) {
            const replaceMeasurementId = search
            window.location.href = `${process.env.REACT_APP_LOGIN_URL}&${replaceMeasurementId}`
        }
        else if (paramsMeasurementId && paramsMeasurementId !== 'null' && paramsMeasurementId !== 'undefined' && !savingId) {
            const tapeMeasurement = await updateTapeMeasureApi(paramsMeasurementId, measurements);
            if (tapeMeasurement) {
                setMessage(true)
            }
        } else {
            const tapeMeasurement = await tapeMeasurementApi({ ...measurements });
            let replaceMeasurementId = '';
            if (search.includes('null')) {
                replaceMeasurementId = search.replace('null', `${tapeMeasurement.id}&saved`)
            } else {
                replaceMeasurementId = search + `&measurementId=${tapeMeasurement.id}&saved`
            }
            window.location.href = `${process.env.REACT_APP_LOGIN_URL}&${replaceMeasurementId}`
        }
    }

    const reducingReturn = () => {
        if (window.opener === null) {
            dispatch(setRecommendations(undefined))
            if (!!authContext.user && (!!UPC || !!productId || (!!brand && !!garment) )) {
                navigate(`/${url.search}`);
            }
        } else {
            window.close();
        }
    }

    const sizes = recommendations[0].sizes?.map((s: any) => s.size) || [];

    const handleConfirm = (variation: any) => {
        if (window.opener == null){
            dispatch(setRecommendations(undefined))
            navigate(`/${url.search}`);
        } else {
            const addUPCInEvent = { upc: variation.eanCode };
    
            const post = (message: any) => {
                const updatedMessage = { ...message, ...addUPCInEvent };
                if (window.opener) {
                    window.opener.postMessage(updatedMessage, '*');
                }
                window.postMessage(updatedMessage, '*');
            };
    
            post(eventMessages.recommendation);
            post(eventMessages.recommendations);
            window.close();
        }
    };

    if (!isSizeChartLoaded) {
        return (
            <div>
                <DesignGifImage src={DesignGIF} alt="Design_GIF" />
                <MeasuringText>{t("pleaseWaitMeasuring")}</MeasuringText>
            </div>
        )
    }

    return (
        <div style={{ width: '100%' }}>
            <Heading >{t('size_recommendation')}</Heading>
            <Heading>{t('size')} - {sizes.join(', ')}</Heading>
            {sizeChart && (
                <ProductInfo>
                    {sizeChart.name && (
                        <div>
                            {t('for')} <Highlight>{sizeChart.name}</Highlight> {sizeChart.brandName && ` (${sizeChart.brandName})`}
                        </div>
                    )}
                    <br />
                    {(sizeChart.variations && sizeChart.variations.length > 0) && <div>{t('available_variations')}</div>}
                    <VariationList>
                        {sizeChart.variations && sizeChart.variations.length > 0 ? (
                            sizeChart.variations.map((variation: any, index: any) => (
                                <VariationItem key={index}>
                                    <VariationDetails>
                                        {index + 1}.
                                        {variation.color && (
                                            <Highlight> {variation.color}-</Highlight>
                                        )}
                                        {variation.fabric && (
                                            <Highlight>{variation.fabric} </Highlight>
                                        )}
                                        ({variation.eanCode})
                                    </VariationDetails>
                                    <SelectButton onClick={() => handleConfirm(variation)}>
                                        {t('confirm')}
                                    </SelectButton>
                                </VariationItem>
                            ))
                        ) : (
                            <VariationItem>
                                <VariationDetails>
                                    {t('suitable_size')} <Highlight>{sizes.join(', ')}</Highlight> {t('choose_another')}
                                </VariationDetails>
                            </VariationItem>
                        )}
                    </VariationList>
                </ProductInfo>
            )}
            <div className="result-footer-container">
            {!(sizeChart.variations && sizeChart.variations.length > 0) && <Button type='submit' variant='outlined' color='primary'
                onClick={reducingReturn}
            >
                {t('Return')}
            </Button>}
            {showMessage && <SubHeading style={{color: 'green', marginTop: '30px'}}>{'Measurements successfully updated!'}</SubHeading>}
            <SaveContainer>
                <SaveButton onClick={handleOnClick}>{t('save_measurements')}</SaveButton>
                <SubHeading>{t('reducingReturn')}</SubHeading>
            </SaveContainer>
            </div>
        </div>
    )
}

export default SizeChartResult;