import * as React from 'react'
import 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

interface FirebaseContextState {
    user?: firebase.User | null;
    auth?: firebase.auth.Auth;
}

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_HOSTING_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_HOSTING_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_HOSTING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_HOSTING_APP_ID
  };

export const firebaseConfig = firebase.initializeApp(config)

export const FirebaseContext = createContext<FirebaseContextState>({});

const FirebaseProvider = (props:any) => {

    const [firebaseContextState, setFirebaseContextState] = useState<FirebaseContextState>({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if (!firebase.apps.length) {
            firebase.initializeApp(config)
        }
        setFirebaseContextState(currentState => ({ ...currentState, auth: firebase.auth() }))
        return firebase.auth().onAuthStateChanged((user) => {
            setFirebaseContextState(current => ({ ...current, user: user }))
            setLoading(false)
        })
    }, [])

    return (
        <FirebaseContext.Provider value={firebaseContextState}>
            {!loading && props.children}
        </FirebaseContext.Provider>
    );
}

export default FirebaseProvider
