const API_KEY = "apikey";
export const fetchSilhouette = async function (
  picture: any,
  height: string,
  gender: string,
  isFront: boolean
) {
  const url = new URL(window.location.href);
  const apiKey: string | null = url.searchParams.get(API_KEY);
  const orientation = isFront ? "front" : "side";

  console.log("Make silhouette ------ ", process.env);
  return await fetch(
    `${process.env.REACT_APP_MEASUREMENT_API}/api/silhouette`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-type": "application/json",
        Authorization: `${apiKey}`,
        "x-sizey-key": `${apiKey}`,
      },
      body: JSON.stringify({
        image: picture,
        height: height,
        gender: gender,
        orientation: orientation,
      }),
    }
  );
};

const fetchPredict = async function (
  front: any,
  side: any,
  gender: string,
  height: string
) {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);

  const frontImg = front.image
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "");

  const sideImg = side.image
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "");

  return await fetch(`${process.env.REACT_APP_MEASUREMENT_API}/api/predict`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-Sizey-Key": `${apiKey}`,
      "X-Sizey-Front-Silhouette-Id": front.id,
      "X-Sizey-Side-Silhouette-Id": side.id,
    },
    body: JSON.stringify({
      gender: gender,
      height: height,
      front: frontImg,
      side: sideImg,
    }),
  });
};

const fetchMeasurements = async function (
  front: any,
  side: any,
  gender: string,
  height: string,
  body3DModelID: string
) {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);

  const frontImg = front.image
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "");

  const sideImg = side.image
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "");

  return await fetch(`${process.env.REACT_APP_MEASUREMENT_API}/api/measure`, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      "X-Sizey-Key": `${apiKey}`,
      "X-Sizey-Front-Silhouette-Id": front.id,
      "X-Sizey-Side-Silhouette-Id": side.id,
    },
    body: JSON.stringify({
      gender: gender,
      height: height,
      front: frontImg,
      side: sideImg,
      '3d_model_path': body3DModelID
    }),
  });
};

const fetchSizeRecommendations = async function (
  measurements: string,
  sizeChart: any,
  sizeType: string
) {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);

  return await fetch(
    `${process.env.REACT_APP_RECOMMENDATION_API}/recommendations`,
    {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        "X-Sizey-Key": `${apiKey}`,
      },
      body: JSON.stringify({
        bodyMeasurements: measurements,
        sizeChart: {
          ...sizeChart,
          sizeType: sizeType,
        },
      }),
    }
  );
};

export const createSilhouette = async (
  picture: any,
  height: string,
  gender: string,
  isFront: boolean
) => {
  picture = picture
    .replace("data:image/png;base64,", "")
    .replace("data:image/jpeg;base64,", "");

  try {
    const resp = await fetchSilhouette(picture, height, gender, isFront);
    if (resp.status === 200) return resp.json();
    else throw new Error();
  } catch (error) {
    throw new Error();
  }
};

export const getMeasurements = async (data: any) => {
  let { gender, height, sidePicture, frontPicture } = data;

  const resp = await fetchPredict(
    frontPicture,
    sidePicture,
    gender,
    height
  ).then((r) => r.json());
  const body3DModelID = resp.body3DModelID;
  const measureRes = await fetchMeasurements(
    frontPicture,
    sidePicture,
    gender,
    height,
    body3DModelID
  ).then((r) => r.json());
  const model = await get3DModel(measureRes.measurementId);
  const measurements = measureRes.bodyMeasurements;
  return {
    ...measurements,
    measurementId: measureRes.measurementId,
    gender,
    height,
    mesh: resp.mesh,
    '3DmodelPath': model,
    timestamp: new Date()
  };
};

export const getMeasurement = async (measurementId: string) => {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY) || `${process.env.REACT_APP_API_KEY}`;
  try {
    const measurement = await fetch(
      `${process.env.REACT_APP_API}/api/measurement-event/${measurementId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Sizey-Key": `${apiKey}`,
        },
      }
    );
    if (measurement.status === 401) {
      return measurement;
    }
    const json = await measurement.json();
    return {
      ...json,
      measurement: { ...json.measurement, timestamp: json.timestamp },
    };
  } catch (error) {
    throw new Error();
  }
};

export const getRecommendations = async (data: any) => {
  let { measurements, sizeChart, sizeType } = data;
  try {
    const resp = await fetchSizeRecommendations(
      measurements,
      sizeChart,
      sizeType
    );
    if (resp.status === 200) return resp.json();
    else return false;
  } catch (error) {
    throw new Error();
  }
};

export const getProductByUpcApi = async (UPC: string, recommendedSize: string, productId: string) => {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);
  return fetch(`${process.env.REACT_APP_VROOM_API_URL}products/recommended/${recommendedSize}?eanCode=${UPC}&externalId=${productId}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Sizey-Key": `${apiKey}`,
    },
  }).then((async res => {
    return await res.json()
  })).catch(err => { return err })
}

export const getProductByIdApi = async (productId: string) => {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);
  return fetch(`${process.env.REACT_APP_VROOM_API_URL}products/${productId}`, {
    headers: {
      "Content-Type": "application/json",
      "X-Sizey-Key": `${apiKey}`,
    },
  }).then((async res => {
    return await res.json()
  })).catch(err => { return err })
}

export const getSizeChartApi = async () => {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);
  const queryParams = url.searchParams.toString();
  const apiUrl = `${process.env.REACT_APP_RECOMMENDATION_API}/sizecharts?${queryParams}`;
  return fetch(apiUrl, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "X-Sizey-Key": `${apiKey}`,
    },
  }).then((async res => {
    return await res.json()
  })).catch(err => { return err })
}


export const get3DModel = async (measurementId: string) => {
  const url = new URL(window.location.href);
  const apiKey = url.searchParams.get(API_KEY);
  return await fetch(
    `${process.env.REACT_APP_API}/api/measurement-event/${measurementId}/3dmodel`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "X-Sizey-Key": `${apiKey}`,
      },
    }
  ).then((async res => {
    if (res.status === 200) {
      return await res.url;
    } else {
      return null;
    }
  })).catch(err => { return null });
}

export const tapeMeasurementApi = async (measurement: any) => {
  const apiKey = process.env.REACT_APP_API_KEY
  return await fetch(`${process.env.REACT_APP_API}/api/measurement-event`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Sizey-Key": `${apiKey}`,
      },
      body: JSON.stringify({
        measurement,
        tape: true
      }),
    },

  ).then((async res => {
    return res.json();
  })).catch(err => { return null });
}

export const updateTapeMeasureApi = async (measurementId:string, measurement: any) => {
  const apiKey = process.env.REACT_APP_API_KEY
  return await fetch(`${process.env.REACT_APP_API}/api/measurement-event/${measurementId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-Sizey-Key": `${apiKey}`,
      },
      body: JSON.stringify({
        measurement
      }),
    },
  ).then((async res => {
    return res.json();
  })).catch(err => { return null });
}
