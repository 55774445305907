import { useEffect } from "react"
import { FunctionComponent } from "react"
import { useLocation } from "react-router"
import firebase from "firebase/compat/app";


const Token: FunctionComponent = (props: any) => {

    const newSearch: any = new URLSearchParams(window.location.search)
    let query1 = ''
    if (newSearch) {
        for (const param of newSearch) {
            if (param[0] !== 'id' && param[0] !== 'path') {
                query1 = query1 + '&' + param[0] + '=' + param[1]
            }
        }
    }

    const location = useLocation()

    useEffect(() => {
        handleToken()
        // eslint-disable-next-line
    }, [])

    const handleToken = async () => {
        const search = location.search
        const querySearch = new URLSearchParams(search).get('path')
        const token = new URLSearchParams(search).get('id')
        if (token) {
            await fetch(`${process.env.REACT_APP_BASE_URL}token/${token}`, {
                method: 'GET'
            }).then(res => res.json())
                .then(async res => {
                    const response = await firebase.auth().signInWithCustomToken(res.token)
                    if (querySearch) {
                        !!response && props.history.push({
                            pathname: querySearch, search: query1.replace('&', ''),
                        });
                        return
                    }
                    !!response && props.history.push({
                        pathname: '/', search: query1.replace('&', ''),
                    });
                })
                .catch(err => console.log('error'))

        }
    }
    return (<></>)
}


export default Token