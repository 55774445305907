import { AuthUserApi, GetUserMeasurements, UserMeasurement, GetPoses, GetAvatar, GetShopsUrlApi } from '../api/user-api'
import { getTapMeasurement, setUserGender, setUserHeight } from './recommendationAction'

export const GET_USER_INFO_START = 'GET_USER_INFO_START'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'

export const GET_MEASUREMENT_USER_SUCCESS = 'GET_MEASUREMENT_USER_SUCCESS'
export const GET_MEASUREMENTS_USER_SUCCESS = 'GET_MEASUREMENTS_USER_SUCCESS'

export const GET_3D_AVATAR_START = "GET_3D_AVATAR_START";
export const GET_3D_AVATAR_SUCCESS = "GET_3D_AVATAR_SUCCESS";
export const GET_3D_AVATAR_ERROR = "GET_3D_AVATART_ERROR";
export const SHOP_URL = "SHOP_URL";

export const getUrlQuery = () => {
    const params: any = new URLSearchParams(window.location.search);
    let URL = '';
    for (const param of params) {
        if ([('UPC' || 'upc' || 'productId'), 'apikey'].includes(param[0])) {
            URL += `${param[0]}=${param[1]}&`
        }
    }
    if (URL) return `?${URL}`;
    return URL;
}

const get3dAvatarStart = () => ({
    type: GET_3D_AVATAR_START,
    payload: {
        isLoading: true
    }
})

const get3dAvatarSuccess = (avatar: any, poses: any) => ({
    type: GET_3D_AVATAR_SUCCESS,
    payload: {
        isLoading: false,
        avatar,
        poses
    }
})

const get3dAvatarError = () => ({
    type: GET_3D_AVATAR_ERROR,
    payload: {
        isLoading: false
    }
})

const getUserInfoStart = (isLoading: boolean) => ({
    type: GET_USER_INFO_START,
    payload: {
        isLoading
    }
})

const getShopUrl = (shops: any) => ({
    type: SHOP_URL,
    payload: {
        shops
    }
})

export const getUserInfoSuccess = (data: any) => ({
    type: GET_USER_INFO_SUCCESS,
    payload: {
        isLoading: false,
        gender: data.gender,
        height: data.height,
        displayName: data.displayName,
        fitPreference: data.fitPreference,
        useTapeMeasurement: data.useTapeMeasurement
    }
})

const getUserInfoError = (errMsg: any) => ({
    type: GET_USER_INFO_ERROR,
    payload: {
        errMsg,
        isLoading: false
    }
})


const getUserMeasurementSuccess = (measurement: any) => ({
    type: GET_MEASUREMENT_USER_SUCCESS,
    payload: {
        measurement: measurement,
        isLoading: false,
    }
})

const getMeasurementsSuccess = (measurements: any) => ({
    type: GET_MEASUREMENTS_USER_SUCCESS,
    payload: {
        measurements: measurements,
        isLoading: false,
    }
})


export const getUserInfo = (data: any) => async (dispatch: any) => {
    dispatch(getUserInfoStart(true))
    try {
        let res: any = await AuthUserApi(data)
        dispatch(getUserInfoSuccess(res))
        dispatch(setUserGender(res.gender));
        dispatch(setUserHeight(res.height));
    } catch (error) {
        dispatch(getUserInfoError(error))
    }
}

export const getShopsUrl = (token: any) => async (dispatch: any) => {
    dispatch(getUserInfoStart(true))
    try {
        let res: any = await GetShopsUrlApi(token)
        dispatch(getShopUrl(res))
    } catch (error) {
        dispatch(getUserInfoError(error))
    }
}

export const userMeasurement = (measurement: any, token: string | undefined) => async (dispatch: any) => {
    dispatch(getUserInfoStart(true))
    try {
        const res: any = await UserMeasurement(measurement, token)
        dispatch(getUserMeasurementSuccess(res))
    } catch (error) {
        dispatch(getUserInfoError(error))
    }
}

export const getUserMeasurements = (token: any, history?: any) => async (dispatch: any) => {
    dispatch(getUserInfoStart(true))
    try {
        const res: any = await GetUserMeasurements(token);
        if (res.length < 1) {
            const url = getUrlQuery();
            history.push(`/${url}`);
        }
        const isTape = res.find((v: any) => v.tape);
        if (isTape) {
            dispatch(getTapMeasurement(isTape.measurement_id))
        }
        dispatch(getMeasurementsSuccess(res.reverse()))
    } catch (error) {
        dispatch(getUserInfoError(error))
    }
}


export const get3dAvtarModel = (token: string, measurementId: string) => async (dispatch: any) => {
    dispatch(get3dAvatarStart());
    try {
        const avatar = await GetAvatar(token, measurementId);
        const poses = await GetPoses(token, avatar.id);
        dispatch(get3dAvatarSuccess(avatar, poses));
    } catch (error) {
        dispatch(get3dAvatarError());
    }
}