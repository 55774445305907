import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  loginContainer: {
    marginRight: '5px'
  },
  signInButton: {
    backgroundColor: '#FFFFFF',
    borderRadius: '20px',
    color: '#003777',
    border: '2px solid #8a8ca5',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    dispaly: 'inline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e3dede',
    },
    width: '285%',
  }
}));

export default function LoginButton({t}: any){
  const location = useLocation();
  const search = (location.search).replace('?', '');
  const classes = useStyles();
  const path = '/recommendation';
  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null;
    }
  };
  return (
    <Grid container className={classes.loginContainer} spacing={5}>
      <Grid item xs={6}>
        <Button
          variant="contained"
          className={classes.signInButton}
          onClick={() => openInNewTab(`${process.env.REACT_APP_LOGIN_URL}&${search}&path=${path}`)}
        >
          {t('Login_measurement_profile')}
        </Button>
      </Grid>
    </Grid>
  );
}